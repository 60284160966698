<template>
  <el-drawer
    v-model="drawer"
    title="查看审核情况"
    :close-on-click-modal="false"
    size="850px"
  >
    <el-row>
      <el-col :span="5">单据号：{{ info.contractNum }} </el-col>
      <el-col :span="8">客户：{{ info.customerName }} </el-col>
      <el-col :span="7">业务类型：{{ info.businessType }} </el-col>
      <el-col :span="4">总金额：{{ info.contractAmount }} </el-col>
    </el-row>
    <div class="box">
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in list"
          :key="index"
          icon="Document"
          :color="index == 0 ? 'var(--themeColor,#17a2b8)' : ''"
          size="large"
        >
          <el-card>
            <div style="color: var(--themeColor, #17a2b8); font-size: 14px">
              {{
                $parseTime(item.createdAt, "{y}-{m}-{d} {h}:{i}:{s}")
              }}&nbsp;&nbsp; {{ item.submitName }}&nbsp;&nbsp;{{
                index == 0 && item.status == 1
                  ? "审核结束"
                  : item.status == 6
                  ? "驳回"
                  : "提交给审核人"
              }}
              &nbsp;&nbsp;{{ item.userName }}
            </div>
            <div style="font-size: 12px; margin-top: 10px">
              备注： {{ item.remark }}
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </el-drawer>
</template>

<script setup>
import { ref } from "vue";
import { approvalHistory } from "@/api/newCrm";
const drawer = ref(false);
const info = ref({});
const list = ref([]);
const init = (row) => {
  info.value = row;
  approvalHistory({ contractId: row.id }).then((res) => {
    if (res.data.msg == "success") {
      list.value = res.data.data.goodsInfo ? res.data.data.goodsInfo : [];
    }
  });
  drawer.value = true;
};
defineExpose({
  init,
});
</script>
<style lang="scss" scoped>
.el-col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box {
  margin-top: 10px;
}
</style>
