<template>
  <div class="app-container" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="top">
      <div v-if="showStatus" class="tabs">
        <div v-for="(tab, index) in tabs" :key="index" class="tab">
          <div class="tab_flex">
            <div  :class="['tab_content', { tab3: index === 3 }]">
              <img :src="tab.img">
              <div class="tab_title">
                {{ tab.name }}
              </div>
            </div>
            <div class="triangle-container" v-if="index != 4">
              <img src="../../assets/sanjiao.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="switch_icon top--10" v-else @click="switchStep"><span>展开引导</span><el-icon class="rotate--90" style=""><d-arrow-left /></el-icon>
      </div>
      <div class="switch_icon bottom-10" v-if="showStatus" @click="switchStep"><span>收起引导</span><el-icon class="rotate--90"><d-arrow-right /></el-icon>
      </div>
    </div>
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input size="small" placeholder="请输入公司名称/编号" v-model.trim="listQuery.name" class="w-200" @keyup.enter="getList" clearable></el-input>
        <el-button class="mr-10" size="small" type="primary" @click="getList">
          <el-icon><Search /></el-icon> <span>搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form class="styleForm w-700" label-width="140px" :inline="true">
            <el-form-item label="纳税人类型：">          
              <selecttaxtype v-model:type="listQuery.type" style="width: 170px;margin: 0;"></selecttaxtype>
            </el-form-item>
            <el-form-item label="选择人员：">   
              <selectuser code="addCustomer" v-model:userId="listQuery.userId" style="width: 170px;margin: 0;" ></selectuser>
            </el-form-item>
            <el-form-item label="税局："> 
              <selectcity v-model:citys="listQuery.districtCode" style="width: 170px;margin: 0;"></selectcity>
            </el-form-item>
            <el-form-item label="标签：">
              <el-input v-model="listQuery.labelLogoEa" size="small" placeholder="请输入标签" style="width: 170px;margin: 0;" clearable></el-input>
            </el-form-item>
            <el-form-item label="授权状态：">   
              <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width: 170px;margin: 0;"></selectAccreditStatus>
            </el-form-item>
            <el-form-item label="公司类型："> 
              <el-select v-model="listQuery.companyType" placeholder="请选择公司类型" size="small"  filterable clearable style="width: 170px;margin: 0;">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号：">   
              <input-common v-model:modelValue="listQuery.mobileTel" style="width:170px"></input-common>
            </el-form-item>
            <el-form-item label="随手记 ：">
              <el-input v-model="listQuery.jotDown" style="width:170px" size="small" placeholder="请输入随手记" />
            </el-form-item>
            <el-form-item label="税种采集状态："> 
              <selectCollectStatus v-model:collectStatus="listQuery.setUpStatusCj"></selectCollectStatus>
            </el-form-item>
            <el-form-item label="上期税表采集状态："> 
              <selectPpStatus v-model:ppStatus="listQuery.setUpStatusPp"></selectPpStatus>
            </el-form-item>
            <el-form-item label="财务初始化状态："> 
              <selectJzStatus v-model:jzStatus="listQuery.setUpStatusJz"></selectJzStatus>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <qzf-video vid="63dd60f588b6bba91af12a2ae19740b7_6"></qzf-video>
        <colSetting name="新增客户" btnName="字段设置" @arrangeInfo="getInfo"  @load="loadingV"/> 
        <el-dropdown v-if="$buttonStatus('xzkh_xz')" split-button type="success" @click="addCustomerCom" size="small" class="m-rl-5" :hide-on-click="false">
         <i class="iconfont icon-jiahao"></i> 新增账套
         <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <span class="import-company-btn" @click="daoruCustomer">导入客户</span>
            </el-dropdown-item>
          </el-dropdown-menu>
         </template>
        </el-dropdown>
        <el-button size="small" type="primary" v-if="$buttonStatus('xzkh_cj')" @click="gather" :loading="gatherLoading">
         <el-icon><Aim /></el-icon> <span  >税局采集</span>
        </el-button>
        <el-button size="small" type="primary" @click="batchEdit" plain class="m-rl-5" :disabled="!$buttonStatus('xzkh_plxg')">
          <el-icon><Edit /></el-icon> <span  >批量修改</span>
        </el-button>
        <el-button size="small" type="danger" @click="delAll" v-if="isAdmin == 1" class="m-l0-r5">
          <el-icon><Delete /></el-icon> <span  >删除</span>
        </el-button>
        <el-dropdown size="small" :hide-on-click="false" >
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a" v-if="$buttonStatus('xzkh_cjyeb')">
                <div @click="handleCommand()">采集科目余额表</div>
              </el-dropdown-item>
              <el-dropdown-item command="b" v-if="$buttonStatus('xzkh_tgcj')">
                <div @click="skipCollection">跳过税局采集</div>
              </el-dropdown-item>
              <!-- <el-dropdown-item command="d" v-if="$buttonStatus('xzkh_cxcj')">
                <div @click="cancelTask()">撤销税局采集</div>
              </el-dropdown-item> -->
              <el-dropdown-item command="h" v-if="$buttonStatus('jzkh_yjpx')">
                <span @click="oneKeySort">一键排序</span>
              </el-dropdown-item>
              <el-dropdown-item command="f">
                <span @click="setLabel">设置标签</span>
              </el-dropdown-item>
              <el-dropdown-item command="f" v-if="$buttonStatus('xzkh_dc')">
                <div @click="customerExport()">导出</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="add-customer-table-box" key="add-customer-table-box">
      <el-table key="add-customer-table-instance" class="table-instance" ref="tableScroll" id="tableLazyLoad" border stripe height="100%" :data="list" @select="handleSelectionChange" @sort-change="sortChange" @select-all="handleSelectionChangeAll" v-loading="loading">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column key="selection" align="center" prop="id" type="selection" width="55" />
        <el-table-column key="sortCom" sortable="custom" label="编号" align="center" prop="sort" width="70" fixed="left" v-if="arrangeInfo.includes('编号')">
          <template #default="scope">
            <TableSortCell :row="scope?.row" />
          </template>
        </el-table-column>
        <el-table-column prop="labelLogoEa" label="标签" width="70" fixed="left" align="center" show-overflow-tooltip
        v-if="arrangeInfo.includes('标签')"></el-table-column>
        <el-table-column key="name" prop="name" fixed="left" label="公司名称" min-width="300" v-if="arrangeInfo.includes('公司名称')">
          <template #default="scope">
            <TagNameCopy :row="scope?.row" :needNameJump="false">
            </TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column key="startAccountPeriod" align="center" prop="startAccountPeriod" label="启用账期" width="82" v-if="arrangeInfo.includes('启用账期')" />
        <el-table-column key="CurPeriod" align="center" prop="CurPeriod" label="当前账期" width="82" v-if="arrangeInfo.includes('当前账期')" />
        <el-table-column key="accountSystem" prop="accountSystem" label="会计制度" width="228" v-if="arrangeInfo.includes('会计制度')">
          <template #default="scope">
            {{$accountSystemFilter(scope.row?.accountSystem)}}
          </template>
        </el-table-column>
        <el-table-column key="districtName" prop="districtName" label="税局" width="65" v-if="arrangeInfo.includes('税局')" />
        <el-table-column key="manager" prop="manager" label="法定代表人" min-width="100" v-if="arrangeInfo.includes('法人代表')" />
        <el-table-column key="setUpStatusCj" label="税种采集状态" min-width="120" v-if="arrangeInfo.includes('税种采集状态')">
          <template #default="scope">
            <el-tooltip placement="top" :disabled="!scope.row?.FailReason" effect="dark">
              <template #content> <div v-html="scope.row?.FailReason"></div> </template>
              <div class="item_icon">
                <i :class="setUpStatusIcon1(scope.row?.setUpStatusCj)"></i>
                <p>{{ setUpStatus1(scope.row?.setUpStatusCj) }}</p>
                <i class="iconfont icon-wenhao setUpStatusCj-icon" v-if="scope.row?.FailReason"></i>
              </div>
            </el-tooltip> 
          </template>
        </el-table-column>
        <el-table-column key="setUpStatusPp" label="上期税表采集" min-width="140" v-if="arrangeInfo.includes('上期税表采集')">
          <template #default="scope">
            <el-tooltip placement="top" :disabled="!scope.row?.PpFailReason" effect="dark">
              <template #content> 
                <div v-html="Tobreak(scope.row?.PpFailReason)"></div>
              </template>
              <div class="item_icon">
                <i :class="setUpStatusIcon3(scope.row?.setUpStatusPp)"></i>
                <p>{{ setUpStatus3(scope.row?.setUpStatusPp) }}</p>
                <i class="iconfont icon-wenhao sqtbcj-icon" v-if="scope.row?.PpFailReason && (scope.row?.setUpStatusPp == 2 || scope.row?.setUpStatusPp == 4)"></i>
              </div>
            </el-tooltip>  
          </template>
        </el-table-column>
        <el-table-column key="setUpStatusJz" label="财务初始化状态" width="170" v-if="arrangeInfo.includes('财务初始化状态')">
          <template #default="scope">
            <!-- 建账 -->
            <el-tooltip placement="top" :disabled="!scope.row?.FailReason || scope.row?.setUpStatusJz == 6" effect="dark">
              <template #content> <div v-html="scope.row?.FailReason"></div> </template>
              <div class="item_icon">
                <i :class="setUpStatusIcon2(scope.row?.setUpStatusJz)"></i>
                <p>{{ setUpStatus2(scope.row?.setUpStatusJz) }}</p>
              </div>
            </el-tooltip>
            <el-button class="jz-btn" @click="add_ea_subject_balance(scope.row)" size="small" type="text" :disabled="!$buttonStatus('xzkh_jz')">导入余额表</el-button>
          </template>
        </el-table-column>
        <el-table-column key="cjjzBussinessStatus" label="科目余额表采集状态" min-width="134" v-if="arrangeInfo.includes('科目余额表采集状态')">
          <template #default="scope">
            <div class="item_icon">
              <i :class="$setUpStatusJzCjIcon(scope.row?.cjjzStatus,scope.row?.cjjzBussinessStatus)"></i>
              <el-tooltip v-if="scope.row?.cjjzBussinessLog" class="scope.row" effect="dark" placement="top-start">
                <template #content>
                  <div v-html="scope.row?.cjjzBussinessLog?scope.row?.cjjzBussinessLog:'' + scope.row?.cjjzLog?scope.row?.cjjzLog:''"> </div>
                </template>
                <p>{{$setUpStatusJzCj(scope.row?.cjjzStatus, scope.row?.cjjzBussinessStatus)}}</p>
              </el-tooltip>
              <span v-else>
                <p>{{$setUpStatusJzCj(scope.row?.cjjzStatus, scope.row?.cjjzBussinessStatus)}}</p>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column key="随手记" align="left" prop="content" label="随手记" min-width="120" v-if="arrangeInfo.includes('备注') && $buttonStatus('jzkh_ssj')">
          <template #default="scope">
            <el-popover v-model:visible="scope.row.visible" placement="bottom" :width="350" trigger="click">
              <el-input
                v-model="noteContent"
                :rows="4"
                type="textarea"
                placeholder="请输入"
              />
              <div class="opera-btn-box">
                <el-button size="small" type="primary" @click="scope.row.visible = false">取消</el-button>
                <el-button size="small" type="primary" @click="editNote(scope.row,2)">确定</el-button>
              </div>
              <template #reference>
                <span class="xgmStyle" @click="smallNotes(scope.row?.id)">记</span>
              </template>
            </el-popover>
            <span>{{scope.row?.content}}</span>
          </template>
        </el-table-column>
        <el-table-column key="operation" align="center" prop="address" label="操作" width="200">
          <template #default="scope">
            <el-button class="m0-p5"  @click="handleUpdate(scope.row)" size="small" type="text" plain :disabled="!$buttonStatus('xzkh_bj')">
              <el-icon><Edit /></el-icon>
              <span> 编辑</span>
            </el-button>
            <el-button class="m0-p5"  size="small" @click="companyfp(scope.row)" plain type="text" :disabled="!$buttonStatus('xzkh_fp')">
              <el-icon><User /></el-icon>
              <span> 分配</span>
            </el-button>
            <el-button class="m0-p5" size="small" plain type="text"  @click="goBook(scope.row)" :disabled="!$buttonStatus('xzkh_zydjzkh')">
              <el-icon><RefreshRight /></el-icon>
              <span> 转记账</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <div class="bottom_button">
      <!--   <el-button size="small" type="primary" plain @click="settingMobile" :disabled="!$buttonStatus('xzkh_plszsjsjh') || !$buttonStatus('sqdl_sjhxs')">
          <el-icon><Iphone /></el-icon><span  > 设置办税手机号</span>
        </el-button> -->
        <el-button size="small" type="success" plain  @click="companyfpAll" :disabled="!$buttonStatus('xzkh_fp')">
          <el-icon><User /></el-icon><span  > 批量分配</span>
        </el-button>
        <el-button size="small" type="warning" plain  @click="automatic" :loading="noNeedLoading" :disabled="!$buttonStatus('xzkh_plswwxcsh')">
          <el-icon><Finished /></el-icon><span  > 无需财务初始化</span>
        </el-button>
        <el-button size="small" plain type="primary" @click="goAllBook()" :disabled="!$buttonStatus('xzkh_zydjzkh')">
          <el-icon><RefreshRight /></el-icon><span  > 转到记账客户</span>
        </el-button>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="userTotal>0" :total="userTotal" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="contentTable"/>
      </div>
    </div>
  </div>
  <!-- 导入客户todo -->
  <el-dialog :close-on-click-modal="false" destroy-on-close title="提示" v-model="dialogTableVisibledaorukehu">
    <div style="margin-bottom:10px;">
      <span style="color: #67c23a;margin-right:20px">导入成功条数:{{callList.success_total}}</span>
      <span style="color:#F15A24;margin-right:20px">导入失败条数:{{callList.fail_total}}</span>
      <span style="color:#F15A24" v-if="callList.fail_reason">导入失败原因:{{callList.fail_reason}}</span>
    </div>
    <div style="height: 450px;overflow: auto;">
      <el-table :data="callList.fail_list" size="small" border>
        <el-table-column align="center" property="name" label="导入失败公司名称"></el-table-column>
        <el-table-column align="center" property="remark" label="原因"></el-table-column>
      </el-table>
    </div>
    <div style="margin: 15px 0; padding-bottom:10px">
      <el-button style="float: right;margin-left:10px" @click="dialogTableVisibledaorukehu = false" size="small" type="primary">关闭</el-button>
    </div>
  </el-dialog>
  <!-- 设置手机号todo -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogTableVisible" title="提示">
    <el-table :data="failList" border>
      <template #empty>
        <el-empty :image-size="160" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="name" label="公司名称" min-width="180" >
        <template #default="scope">
          {{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="失败原因" min-width="180" >
        <template #default="scope">
          {{scope.row.remark}}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
  <addCustomers @success="getList" ref="addCustomerCom"></addCustomers>
  <allotEdit ref="allotEdit" @success="getList" :listQuery1="this.listQuery" />
  <delCom ref="delCom" @success="getList"/>
  <companyFp ref="companyFp" code="bookkeeping" @success="getList"></companyFp>
  <qzf-import type="daoruCustomer" ref="daoruCustomer" name="客户" @success="getList()" @handleSuccess="getListBegin"></qzf-import>
  <div
    id="message"
    class="el-message el-message--warning"
    style="top:16px;z-index:2087;display: none;align-items: normal;"
  >
    <el-icon style="color: #e6a23c;margin-right: 4px;">
      <warning-filled />
    </el-icon>

    <p class="el-message__content" @click="toBookkeeping" style="cursor: pointer;">
      企业“{{comName}}”已成功转入记账客户，点击<a> 跳转 </a>查看
    </p>
    <el-icon style="margin-left: 10px;color: #999;cursor: pointer;" @click="close">
      <close />
    </el-icon>
  </div>
  <companySort ref="companySort" @success="getList" :status="1"/>
</template>

<script>
import { eaCompanyList ,comMemoryInfoNewly ,comMemorySaveNewly,changeStatus,saveEaCompany,companyEaSetLable } from '@/api/company'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectuser from "@/components/Screening/selectuser";
import selectcity from "@/components/Screening/selectcity";
import selectCollectStatus from "@/components/Screening/selectCollectStatus";
import selectJzStatus from "@/components/Screening/selectJzStatus";
import selectPpStatus from "@/components/Screening/selectPpStatus";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import addCustomers from './components/addCustomers.vue'
import allotEdit from "./components/allotEdit.vue";
import delCom from "./components/delCom.vue";
import { sendTask, quitTask ,checkPassword } from "@/api/collection"
import { assignCustomers } from "@/api/printSet"
import companyFp  from "./components/companyFp.vue";
import { automaticAccountCreation } from "@/api/build"
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'
import companySort from './components/companySort.vue';
export default {
  name: "addCustomer",
  components:{
    selecttaxtype,
    selectuser,
    selectcity,
    selectCollectStatus,
    selectJzStatus,
    selectPpStatus,
    selectAccreditStatus,
    addCustomers,
    allotEdit,
    delCom,
    companyFp,
    TagNameCopy,
    TableSortCell,
    companySort
  },
  data(){
    return{
      typeOptions: COMPANY_TYPE_OPTION,
      listQuery: {
        page: 1,
        limit: 20,
        status:"1",
        allStatus:[],
        companyType:'',
        jotDown:'',
        labelLogoEa:'',
      },
      loading:false,
      showStatus:true,
      isAdmin:this.$store.getters['user/user'].isAdmin,
      list:[],
      userTotal:0,
      contentStyleObj:{},
      arrangeInfo:["编号","公司名称","当前账期","办税手机号","会计制度","税局","负责人","随手记","操作"],
      selects:[],
      ids:[],
      noteContent:'',
      visible:false,
      dialogConfirm:false,
      noNeedLoading:false,
      callList:[],
      dialogTableVisibledaorukehu:false,//导入客户失败
      dialogTableVisible:false,//设置手机号失败
      failList:[],
      pageStatus: true,
      gatherLoading:false,
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false,
      comName:"",
      fullscreenLoading:false,
      tabs: [
        {
          name: "第一步：新增账套",
          value: 1,
          img: require("@/assets/ztqr.png"),
        },
        {
          name: "第二步：授权登录",
          value: 5,
          img: require("@/assets/sqdl.png"),
        },
        {
          name: "第三步：税局采集",
          value: 5,
          img: require("@/assets/sjcj.png"),
        },
        {
          name: "第四步：财务初始化 / 设为无需初始化",
          value: 5,
          img: require("@/assets/csh.png"),
        },
        {
          name: "第五步：转入记账客户",
          value: 5,
          img: require("@/assets/zrjz.png"),
        },
      ]
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(290)
    setTimeout(() => {
      const tableBox = document.querySelector('.add-customer-table-box')
      if (tableBox) {
        tableBox.style.height = this.contentStyleObj
      }
    })
    this.listQuery.limit = localStorage.getItem('contentTable') ? localStorage.getItem('contentTable')*1 : 20
    // this.getList()
    this.init()
    this.initBus()
  },
  methods:{
    // 重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        page: 1,
        limit: originLimit,
        status:"1",
        allStatus:[],
        type:null,
        userId:null,
        districtCode:null,
        keepStatus:null,
        setUpStatusCj:null,
        setUpStatusPp:null,
        setUpStatusJz:null,
        companyType:'',
        jotDown:'',
      }
      this.getList()
    },
    init(){
      this.$bus.off("addCustomerUpdate")
      this.$bus.on("addCustomerUpdate", () => {
        this.getList()
      });
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.ids && this.ids.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if ([3,99].includes(res.setUpStatusCj) || [3,99].includes(res.setUpStatusPp)) {
            status = true;
          }
        })
        if(status == true) {
          eaCompanyList(this.listQuery).then(res=>{
            this.tableData = res.data?.data?.list || []
            this.userTotal = res.data?.data?.total || []
            this.setScroll()
          })
        }
      });
    },
    getList(){
      this.loading = true
      eaCompanyList(this.listQuery).then(res=>{
        this.loading = false
        if(res.data.msg == 'success'){
          this.tableData = res.data?.data?.list || []
          this.userTotal = res.data?.data?.total || 0
          this.setScroll()
        }
      })
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.ids = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = [...this.tableData.slice(0,this.currentPage*this.pageSize)]
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = [...that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            )];
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.id == v.id){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    //switchStep
    switchStep(){
      this.showStatus = !this.showStatus
      if(this.showStatus){
        this.contentStyleObj = this.$getHeight(290)
      }else{
        this.contentStyleObj = this.$getHeight(225)
      }
      setTimeout(() => {
      const tableBox = document.querySelector('.add-customer-table-box')
      if (tableBox) {
        tableBox.style.height = this.contentStyleObj
      }
    })
    },
    //导入
    daoruCustomer(){
      this.$refs.daoruCustomer.handRedImport()
    },
    getListBegin(e){
      this.callList = e
      this.dialogTableVisibledaorukehu = true
      this.getList()
    },
    getIds(){
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      this.ids = ids
    },
    //多选
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.id == v.id){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.selects = []
        this.ids = []
        this.allChecked = false
      }
    },
    //显示列设置
    loadingV(){
      this.loading = true
    },
    getInfo(e){
      this.arrangeInfo = e
      this.getList()
    },
    //新增客户
    addCustomerCom(){
      this.$refs.addCustomerCom.handleCreate()
    },
    //税种采集
    gather() {
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      //过滤授权状态
      let str = ''
      this.selects.map(v=>{
        if(v.district != 'zhejiang' && v.district != 'guangdong' && v.district != 'tianjin' && v.district != 'hubei' && v.loginMethod == '新版登录' && v.yzmType == 1 && v.keepStatus != 1){
          str = v.name + '未授权登录，请先授权再发起任务！'
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1)
        return
      }
      let status = false
      this.selects.map(v=>{
        if(v.setUpStatusCj == 1 || v.setUpStatusCj == 2){
          status = true
          return
        }
      })
      if(status){
        this.$prompt("注：此操作会清空账套内全部数据，请谨慎操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue:'',
        title:'请输入 本软件登录密码',
        customClass:'classPrompt',
        beforeClose:(action,instance,done)=>{
          if(action == 'confirm'){
            let value = instance.inputValue;
            if(value && value != ''){
              checkPassword({password:value}).then(res=>{
                if(res.data.msg == 'success'){
                  done();
                  this.sendCjTask()
                }
              })
            }else{
              this.$qzfMessage('请输入密码',1)
            }
          }else{
            done()
          }
        }
       })
      }else{
        this.sendCjTask()
      }
    },
    sendCjTask(){
      this.gatherLoading = true
      let param = {
        taskName: 'tax-cj-jianzhang',
        comIds: this.ids
      }
      sendTask(param).then(res => {
        this.gatherLoading = false
        if(res.data.msg == "success") {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //批量修改
    batchEdit(){
      // if(this.selects.length == 0){
      //   this.$qzfMessage("请选择公司", 1) 
      //   return
      // }
      this.$refs.allotEdit.init(this.selects)
    },
    //批量删除
    delAll(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.$refs.delCom.init(this.selects)
      // this.$refs.delCom.init(this.selects,'customer')
    },
    //采集科目余额表
    handleCommand() {
      this.$store.dispatch('tagsView/delCachedViewByName', "addAccounts")
      this.$router.push({
        path: "./addAccounts.vue",
        name: "addAccounts"
      });
    },
    //跳过税局采集
    skipCollection(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let cjStatus = false
      this.selects.map(v=>{
        if(v.setUpStatusCj == 3 || v.setUpStatusCj == 9){
          cjStatus = true
          return
        }
      })
      if(cjStatus){
        this.$qzfMessage("当前选择公司正值采集中或排队中,不可跳过采集", 1)
        return
      }
      let status = false
      this.selects.map(v=>{
        if(v.setUpStatusCj == 1 || v.setUpStatusCj == 2){
          status = true
          return
        }
      })
      if(status){
        this.$prompt("注：此操作会清空账套内全部数据，请谨慎操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue:'',
        title:'请输入 本软件登录密码',
        customClass:'classPrompt',
        beforeClose:(action,instance,done)=>{
          if(action == 'confirm'){
            let value = instance.inputValue;
            if(value && value != ''){
              checkPassword({password:value}).then(res=>{
                if(res.data.msg == 'success'){
                  done();
                  this.sendJumpTask()
                }
              })
            }else{
              this.$qzfMessage('请输入密码',1)
            }
          }else{
            done()
          }
        }
       })
      }else{
        this.sendJumpTask()
      }
    },
    sendJumpTask(){
      this.loading = true
      let param = {
        taskName: 'tax-cj-jump',
        comIds: this.ids
      }
      sendTask(param).then(res => {
        this.loading = false
        if(res.data.msg == "success") {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
          setTimeout(()=>{
            eaCompanyList(this.listQuery).then(res=>{
              this.tableData = res.data.data.list ? res.data.data.list : []
              this.userTotal = res.data.data.total
              this.setScroll()
            })
          },2000)
        }
      })
    },
    //撤销
    cancelTask(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$confirm('确定撤销采集任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        quitTask({taskName:'tax-cj-jianzhang', comIds: this.ids}).then(res => {
          if(res.data.msg == 'success') {
            this.$cancelMsg(res.data.data.list)
            this.getList()
          }
        })
      })
    },
    //导出
    customerExport(){
      let param = {
        query:this.listQuery,
        ids:[]
      }
      this.selects.map(v=>{
        param.ids.push(v.id)
      })
      assignCustomers(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    //小记
    smallNotes(id){
      this.visible = true
      this.dialogConfirm = false
      comMemoryInfoNewly({comId:id}).then(res=>{
        this.noteContent = res.data.data.content
      })
    },
    editNote(row,e){
      if(e == 2){
        this.dialogConfirm = true
      }
      comMemorySaveNewly({comId:row.id,content:this.noteContent}).then(res=>{
        row.content = this.noteContent
        if(res.data.msg == "success"){
          row.visible = false
          this.$qzfMessage("保存成功")
        }
      })
    },
    //编辑
    handleUpdate(e){
      this.$store.dispatch('commons/setParam', {editCustomerComId: e})
      this.$router.push({
        path: "/company/comInfo",
        name:"editCustomer"
      });
    },
    //转记账
    goBook(row){
      if(row.setUpStatusJz == 6){
        this.$qzfMessage("请先进行财务初始化，新企业点击“无需初始化”之后，再转记账客户", 1)
        return
      }
      row.status = 2
      this.$confirm('确定要转到记账客户吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus([row]).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("设置成功")
            this.getList()
            this.comName = row.name
            let box = document.getElementById("message")
            box.style.display = 'flex';
            setTimeout(()=>{
              box.style.display = 'none';
            },5000)
          }
        })
      });
    },
    close(){
      document.getElementById("message").style.display = 'none';
    },
    //批量转记账
    goAllBook(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      let str = ''
      this.selects.map(v=>{
        if(v.setUpStatusJz == 6){
          str = v.name + '未财务初始化，请先进行财务初始化'
        }
      })
      if(str){
        this.$qzfMessage(str, 1)
        return
      }
      this.selects.map(v=>{
        v.status = 2
      })
      this.$confirm('确定要转到记账客户吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus(this.selects).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("已成功转入记账客户")
            this.getList()
          }
        })
      });
    },
    // 批量无需建账
    automatic(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let str = ""
      this.selects.map(v=>{
        if(v.setUpStatusCj != 1 && v.setUpStatusCj != 2){
          str = v.name + '未采集，请先进行税种采集'
        }
      })
      if(str){
        this.$qzfMessage(str, 1)
        return
      }
      let arr = []
      this.selects.map(v=>{
        arr.push({comId:v.id})
      })
      let param = {
        params:arr
      }
      this.noNeedLoading = true
      automaticAccountCreation(param).then(res=>{
        this.noNeedLoading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("设置成功") 
            this.getList()
          }
      })
    },
    //分配
    companyfp(row){
      let ids = row.id
      ids = [ids]
      this.$refs.companyFp.init(ids,row)
    },
    //批量分配
    companyfpAll(){
      this.$refs.companyFp.init(this.ids)
    },
    // 建账
    add_ea_subject_balance(e) {
      if(e.setUpStatusCj != '1' && e.setUpStatusCj != '2'){
        this.$qzfMessage('请先采集信息',1)
        return
      }
      // 新版跳转
      this.$store.dispatch('commons/setParam', {addCustomerComId: e.id,addCustomerComName:e.name,addCustomerComPeriod:e.startAccountPeriod})
      this.$store.dispatch("tagsView/delCachedViewByName", "subject_balance_uploadExcel_new");
      this.$router.push({
        path: "/company/subject_balance_uploadExcel_new",  
      });
    },
    //设置办税手机号
    settingMobile(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司",1)
        return
      }
      this.$prompt('请输入办税人/法人手机号', '设置办税手机号', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue:'',
        beforeClose:(action,instance,done)=>{
          if(action == 'confirm'){
            let value = instance.inputValue;
            if(value &&!/^1[3456789]\d{9}$/.test(value)){
              this.$qzfMessage('请输入正确手机号',1)
            }else{
              this.selects.map(v=>{
                v.mobileRec = value
              })
              // 用于一些手机号设置失败的公司
              saveEaCompany({list:this.selects}).then(res=>{
                done();
                if(res.data.data.fail_total != 0){
                  this.$qzfMessage("设置失败",2) 
                  this.failList = res.data.data.fail_list
                  this.dialogTableVisible = true
                  this.getList()
                }else{
                  this.dialogFormVisible = false;
                  this.$qzfMessage("设置成功") 
                  this.getList()
                }
              })
            }
          }else{
            done()
          }
        }
      })
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.orderBy = "ea_company.sort_com desc,ea_company.id desc"
      }else{
        this.listQuery.orderBy = "ea_company.sort_com,ea_company.id desc"
      }
      this.getList()
    },
    //过滤税种采集状态
    setUpStatus1(type) {
      if (type == 1) {
        return "已采集";
      } else if (type == 6) {
        return "未采集";
      } else if (type == 2) {
        return "跳过采集";
      } else if (type == 3) {
        return "排队中";
      } else if (type == 4) {
        return "采集失败";
      } else if (type == 5) {
        return "部分采集失败";
      } else if (type == 9) {
        return "采集中";
      }else if (type == 7) {
        return "任务已撤销";
      }else if (type == 99) {
        return "发起中";
      }
    },
    setUpStatusIcon1(type){
      if (type == 1) {
        return "iconfont icon-duihao";
      } else if (type == 6) {
        return "iconfont icon-gantanhao";
      } else if (type == 2) {
        return "iconfont icon-info";
      } else if (type == 3) {
        return "iconfont icon-info";
      } else if (type == 4) {
        return "iconfont icon-cuowu";
      } else if (type == 5) {
        return "iconfont icon-duihao duihao-yc";
      } else if (type == 9) {
        return "iconfont icon-info";
      }else if (type == 7) {
        return "iconfont icon-cuowu";
      }else if (type == 99) {
        return "iconfont icon-info";
      }
    },
    //过滤上期税表采集
    setUpStatus3(type){
      if (type == 1) {
        return "采集成功";
      } else if (type == 6) {
        return "未采集";
      } else if (type == 2) {
        return "部分采集失败";
      } else if (type == 3) {
        return "排队中";
      } else if (type == 4) {
        return "采集失败";
      }else if (type == 9) {
        return "采集中";
      }else if (type == 7) {
        return "任务已撤销";
      }else if (type == 10) {
        return "跳过采集";
      }else if (type == 99) {
        return "发起中";
      }
    },
    setUpStatusIcon3(type){
      if (type == 1) {
        return "iconfont icon-duihao";
      } else if (type == 6) {
        return "iconfont icon-gantanhao";
      } else if (type == 2) {
        return "iconfont icon-duihao duihao-yc";
      } else if (type == 3) {
        return "iconfont icon-info";
      } else if (type == 4) {
        return "iconfont icon-cuowu";
      }else if (type == 9) {
        return "iconfont icon-info";
      }else if (type == 7) {
        return "iconfont icon-cuowu";
      }else if (type == 10) {
        return "iconfont icon-info";
      }else if (type == 99) {
        return "iconfont icon-info";
      }
    },
    //过滤财务初始化状态
    setUpStatus2(type) {
      if (type == 1) {
        return "已初始化";
      } else if (type == 6) {
        return "未初始化";
      }else if (type == 2) {
        return "无需初始化"; //绿色
      }else if (type == 4) {
        return "请手动初始化"; //黄色
      }
    },
    setUpStatusIcon2(type){
      if (type == 1) {
        return "iconfont icon-duihao";
      } else if (type == 6) {
        return "iconfont icon-gantanhao";
      }else if (type == 2) {
        return "iconfont icon-duihao"; //绿色
      }else if (type == 4) {
        return "iconfont icon-gantanhao gantanhao-yc"; //黄色
      }
    },
    Tobreak(val){
      return val.replace(/[\r\n]/g, '<br/>')
    },
    toBookkeeping(){
      document.getElementById("message").style.display = 'none';
      this.fullscreenLoading = true;
      this.$store.dispatch("user/setModel", "财务核算");
      this.$store
        .dispatch("user/GetUserInfo")
        .then((res) => {
          let indexPath = res.indexPath;
          this.$store
            .dispatch("router/SetAsyncRouter", res.roles)
            .then(() => {
              this.fullscreenLoading = false
              this.$router.push(indexPath);
              this.$store.dispatch("tagsView/delAllViews");
            });
        })
        .catch(() => {});
    },
    setLabel() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$prompt('请输入标签', '设置标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: '',
        beforeClose: (action, instance, done) => {
          if (action == 'confirm') {
            let value = instance.inputValue;
            let param = {
              ids: this.selects.map(v => v.id),
              labelLogoEa: value
            }
            companyEaSetLable(param).then(res => {
              done();
              if (res.data.msg == "success") {
                this.$qzfMessage("设置成功")
                this.getList()
              }
            })
          } else {
            done()
          }
        }
      })
    },
    //一键排序
    oneKeySort() {
      let ids = []
      this.selects.map(v => {
        ids.push(v.id)
      })
      this.$refs.companySort.init(ids)
    },
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(229, 229, 229, 1);
  padding-bottom: 3px;
}

.tabs img {
  width: auto;
  height: 60px;
}

.tab_content {
  width: 130px;
  text-align: center;
  cursor: pointer;
}
.tab3{
  width: 225px;
}
.tab_title {
  font-size: 13px;
}

.tab_flex {
  display: flex;
  align-items: center;
}
.triangle-container {
  margin: 0 30px;
  img {
    width: 26px;
    height: auto;
  }
}
.switch_icon{
  position: absolute;
  right:2px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  z-index: 1;
  display: flex;
  align-items: center;
  span{
    font-size: 12px;
    margin-right: 2px;
  }
}
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.el-select{
  margin: 0px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }
  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
.bottom_button{
  margin-top: 16px;
  float: left;
}
:deep(textarea){
  min-height: 145px !important;
}
.top{
  position: relative;
}
.el-message__content a{
  text-decoration: underline;
  color: #409eff;
}
:deep(.el-dropdown-menu__item){
  display: block;
}
.top--10 {
  top: -10px;
}
.bottom-10 {
  bottom:10px;
}
.rotate--90 {
  transform: rotate(-90deg);
}
.import-company-btn {
  width:100px;display: inline-block;text-align: center;
}
.table-cell-sort-render {
  color: orange;cursor: pointer;
}
.company-name-box {
  display: flex;align-items: center;
  .item_icon {
    width: 95%;
  }
  .icon-fuzhi1 {
    color:var(--themeColor,#17a2b8);cursor: pointer;
  }
}
.mobileRec-icon {
  color:#67c23a;cursor: pointer;font-weight:600;display: inline-block;margin-left:4px
}
.mobile-no-auth {
  display: inline-block;margin-left:4px;
}
.mobile-no-auth-icon {
  color:#afacac;cursor: pointer;font-weight:600
}
.has-auth-icon {
  color: var(--themeColor,#17a2b8);cursor: pointer;font-weight:600;
}
.setUpStatusCj-icon {
  color:red;margin-left: 4px;
}
.opera-btn-box {
  text-align: right; margin-top: 10px
}
.xgmStyle {
  cursor: pointer;
}
.sqtbcj-icon {
  color:red;margin-left: 4px;
}
.jz-btn {
  border:0;
  padding: 5px 4px;
}
.sq-content-box {
  display: inline-block;margin-left:4px
}
.table-instance {
  width: 100%
}

.m0-p5{
  margin-left: 0px;
}
.m-l0-r5{
  margin-left: 0px;
  margin-right: 5px;
}
</style>