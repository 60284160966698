<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="新增账套" v-model="dialogFormVisible" width="60%">
    <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="170px" :inline="true" size="small">
      <div class="common_dialog_box clearfix">
        <div class="common_title record">企业基本信息</div>
        <div class="item_one clearfix">
        <el-form-item label="企业名称:" prop="name" @click.prevent="(()=>{})">
          <el-autocomplete class="w-200" v-model="temp.name" valueKey="name" :fetch-suggestions="querySearch" @select="handleSelect" placeholder="请输入内容"></el-autocomplete>
        </el-form-item>
        <el-form-item label="所属税务总局:" prop="districtCode" @click.prevent="(()=>{})">
          <selectcity style="width:200px !important;" v-model:citys="temp.districtCode"></selectcity>
        </el-form-item>
        <el-form-item  label="纳税人识别编号:" prop="taxNo" @click.prevent="(()=>{})">
          <input-common v-model:modelValue="temp.taxNo" class="w-200"></input-common>
        </el-form-item>
        <el-form-item label="纳税人资格:" prop="type" @click.prevent="(()=>{})">
          <selecttaxtype style="width:200px !important;" v-model:type="temp.type"></selecttaxtype>
        </el-form-item>
        <el-form-item label="公司注册时间:" @click.prevent="(()=>{})">
          <el-date-picker :picker-options="pickerOptions" v-model="temp.comSetUpYear" type="date" clearable placeholder="公司注册时间" style="width:200px;" value-format="YYYY-MM-DD"></el-date-picker>
        </el-form-item>
        </div>
      </div>
      <div class="common_dialog_box clearfix">
        <div class="common_title record">
          账套信息
          <span> <i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c;margin-right:4px"></i>温馨提示：请正确选择会计制度，账套启用后不可修改！</span>
        </div>
        <div class="item_one clearfix">
          <el-form-item label="企业会计制度:" prop="accountSystem" @click.prevent="(()=>{})">
            <el-select v-model="temp.accountSystem" placeholder="请选择会计准则" class="w-200">
              <el-option v-for="item in kjOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司类型:" prop="companyType" @click.prevent="(()=>{})">
            <el-select v-model="temp.companyType" placeholder="请选择公司类型" class="w-200">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账套启用时间:" prop="startAccountPeriod" @click.prevent="(()=>{})">
            <el-date-picker :picker-options="pickerOptions" v-model="temp.startAccountPeriod" type="month" placeholder="启用月" style="width:200px;" size="small"></el-date-picker>
            <span style="color:red;"><i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c;margin:0 3px"></i>注意：账套启用时间创建之后，无法修改，只能重置账套！</span>
          </el-form-item>
          <el-form-item label="账套截止时间:" @click.prevent="(()=>{})">
            <el-date-picker :picker-options="pickerOptions" v-model="temp.duePeriod" clearable type="month" placeholder="截止月" style="width:200px;" size="small" value-format="YYYYMM"></el-date-picker>
            <el-tooltip content="截止账期为最后一个结账账期，非必选，可设置为空" placement="top">
                <i class="iconfont icon-wenhao" style="color:red;cursor: pointer;font-size:13px;position:absolute;right:-20px"></i>
              </el-tooltip>
          </el-form-item>
        </div>
      </div>
      <div class="common_dialog_box clearfix">
        <div class="common_title record">电子税局登录信息</div>
        <div class="item_one clearfix">
          <el-form-item  label="登录方式:" @click.prevent="(()=>{})" prop="loginMethod">
            <select-common
              placeholder="请选择登录方式"
              v-model:modelValue="temp.loginMethod"
              :options="loginOptions"
              class="w-200"
            />
          </el-form-item>
          <el-form-item label="用户名/手机号:" @click.prevent="(()=>{})" prop="zzridno" v-if="temp.loginMethod != '政务网登录'">
            <el-autocomplete v-model="temp.zzridno" class="w-200" :fetch-suggestions="searchZzridNo" @select="handleSelectZzridno">
              <template #default="{ item }">
                <div>{{ item.loginMethod }}（{{item.zzridno}}）</div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item
            :label="temp.loginMethod == '政务网登录' ? '账号：' : '代理机构号：'"
            prop="idNo"
            v-if="temp.loginMethod != '新版登录'"
          >
           <input-common v-model:modelValue="temp.idNo" class="w-200" :placeholder="temp.loginMethod == '政务网登录' ? '请输入账号' : '请输入代理机构号(纳税人识别号)'"></input-common>
          </el-form-item>
          <el-form-item label="密码：" prop="zzrmn">
            <el-input v-model="temp.zzrmn" class="w-200"></el-input>
          </el-form-item>
          <el-form-item
            label="短信接收方式"
            prop="yzmType"
            v-if="temp.loginMethod != '政务网登录'"
          >
            <select-common
              placeholder="请选择短信接收方式"
              v-model:modelValue="temp.yzmType"
              :options="yzmOptions"
              class="w-200"
              :clearable="false"
            />
          </el-form-item>
        </div>
      </div>
      <div class="common_dialog_box clearfix">
        <div class="common_title record">
          个税登录信息
        </div>
        <div class="item_one clearfix">
          <el-form-item label="个税登录方式:" prop="personalLoginType" @click.prevent="(()=>{})"> 
            <el-select v-model="temp.personalLoginType" placeholder="请选择个税登录方式" style="width:200px;" size="small">
              <el-option v-for="item in personal_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="个税申报密码:" prop="personalTaxPwd" @click.prevent="(()=>{})">
            <input-common v-model:modelValue="temp.personalTaxPwd" class="w-200" placeholder="请输入个税申报密码"></input-common>
            <span class="help" @click="checkImage('gs')">帮助<i class="iconfont icon-wenhao"></i></span>
          </el-form-item>
          <el-form-item v-if="temp.personalLoginType == '实名登录'" label="个税实名账号:" prop="personalIdno" @click.prevent="(()=>{})">
            <input-common v-model:modelValue="temp.personalIdno" class="w-200"></input-common>
          </el-form-item>
        </div>
      </div>
      <div class="common_dialog_box clearfix" v-if="temp.districtCode == '360000'">
        <div class="common_title record">
          社保登录信息
        </div>
        <div class="item_one clearfix">
          <el-form-item label="社保登录方式:" @click.prevent="(()=>{})"> 
            <el-select v-model="temp.socialLoginMethod" placeholder="请选择社保登录方式" style="width:200px;" size="small">
              <el-option v-for="item in sb_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="社保密码:"  @click.prevent="(()=>{})">
            <input-common v-model:modelValue="temp.socialPassword" class="w-200" placeholder="请输入社保密码"></input-common>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
      <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="createData()">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogTableVisible" title="提示">
    <el-table :data="failList" border>
      <template #empty>
        <el-empty :image-size="160" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="name" label="公司名称" min-width="180" >
        <template #default="scope">
          {{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="失败原因" min-width="180" >
        <template #default="scope">
          {{scope.row.remark}}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
   <!-- 图片预览 -->
   <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="imgSrc"
  />
</template>

<script>
import { saveEaCompany,GetQccNew,companyLoginUserList} from "@/api/company"
import {  parseTime  } from "@/utils";
import selectcity from "@/components/Screening/selectcity";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import { ACCOUNT_SYSTEM_OPTION,COMPANY_TYPE_OPTION } from '@/utils/commonData.js'
import { clear } from "xe-utils";
export default {
  name:'addCustomers',
  components:{
    selectcity,
    selecttaxtype,
  },
  data() {
    return {
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() > new Date();
        },
      },
      kjOptions:ACCOUNT_SYSTEM_OPTION,
      typeOptions:COMPANY_TYPE_OPTION,
      dialogFormVisible: false,
      restaurants: [],
      temp: {
        name: "",
        districtCode: "",
        districtName:'',
        regDate: "",
        taxNo: "",
        type: "",
        accountSystem: "",
        loginMethod: "新版登录",
        personalLoginType: "申报密码登录",
        personalIdno: "",
        personalTaxPwd: "",
        startAccountPeriod:'',
        duePeriod:"",
        password: "",
        xzsf:'',
        idNo: "",
        idType: "居民身份证",
        jbr: '',
        jbrIdno: '',
        zzridno:'',
        zzrmn:'',
        zzrxm:'',
        companyType:'有限责任公司',
        mobileRec:"",
        socialLoginMethod:'账号密码登录',
        yzmType:1
      },
      state_tax_mode: [],
      personal_type:[
        {
          value:'实名登录',
          label:'实名登录'
        },
        {
          value:'申报密码登录',
          label:'申报密码登录'
        }
      ],
      sb_type:[{
        value:'账号密码登录',
        label:'账号密码登录'
      }],
      city:[],
      rules: {
        name: [
          { required: true, message: "公司名不能为空", trigger: "change" }
        ],
        districtCode: [
          { required: true, message: "所属税务总局不能为空", trigger: "change" }
        ],
        startAccountPeriod: [
          { required: true, message: "请选择账号启用时间", trigger: "change" }
        ],
        taxNo: [
          { required: true, message: "请输入纳税人识别编号", trigger: "change" },
          { min: 15,  message: '税号至少15位', trigger: 'blur' }
        ],
        type: [
          { required: true, message: "请选择纳税人资格", trigger: "change" }
        ],
        accountSystem: [
          { required: true, message: "请选择企业会计制度", trigger: "change" }
        ],
        companyType: [
          { required: true, message: "请选择企业类型", trigger: "change" }
        ],
        loginMethod: [
          { required: true, message: "请选择国税登录方式", trigger: "change" }
        ],
        personalLoginType: [
          { required: true, message: "请选择个税登录方式", trigger: "change" }
        ],
        personalIdno: [
          { required: true, message: "请输入个税实名账号", trigger: "change" }
        ],
        personalTaxPwd: [
          { required: true, message: "请输入个税申报密码", trigger: "change" }
        ],
        zzrmn: [
          { required: true, message: "请输入密码", trigger: "change" }
        ],
        zzridno: [
          { required: true, message: "请输入用户名或手机号", trigger: "change" }
        ],
        zzrxm: [
          { required: true, message: "请输入办税员姓名", trigger: "change" }
        ],
        password: [
          { required: true, message: "请输入国税登录密码", trigger: "change" }
        ],
        xzsf: [
          { required: true, message: "请选择身份", trigger: "change" }
        ],
        idNo: [
          { required: true, message: "请输入代理机构号/账号", trigger: "change" }
        ],
        idType: [
          { required: true, message: "请选择证件类型", trigger: "change" }
        ],
        jbrIdno: [
          { required: true, message: "请输入经办人身份证号", trigger: "change" }
        ],
        jbr: [
          { required: true, message: "请输入经办人", trigger: "change" }
        ],
        mobileRec: [
          { required: true, message: "请输入办税手机号", trigger: "change" }
        ],
        yzmType:[
         { required: true, message: "请选择短信接收方式", trigger: "change" }          
        ]
      },
      dialogTableVisible:false,
      failList:[],
      showImagePreview: false,
      imgSrc: [],
      loginOptions:[
        { label: "新版登录", value: "新版登录" },
        { label: "代理登录", value: "代理登录" },
        { label: "政务网登录", value: "政务网登录" },
      ],
      yzmOptions:[
        { label: "自动登录", value: 0 },
        { label: "手动登录", value: 1 },
      ]
    }
  },
  created() {
  },
  methods:{
    searchZzridNo(queryString, cb){
      if(queryString == "" || queryString.length < 2){
        return
      }
      companyLoginUserList({zzridno:queryString,loginMethod:this.temp.loginMethod}).then(res=>{
        let results = res.data?.data?.list || []
          if(results){
            cb && cb(results);
          }
      })
    },
    handleSelectZzridno(item){
      this.temp.zzridno = item.zzridno
      this.temp.yzmType = item.yzmType
      this.temp.zzrmn = item.zzrmn
      this.temp.idNo = item.idNo
    },
    querySearch(queryString, cb) {
      if(queryString == "" || queryString.length < 4){
        return
      }
      let params = {
        name:queryString
      }
      // axios
      //   .post("http://47.104.151.140:2222/api/getQcc", params)
      //   .then( res => {
      //     if (res.data.msg == "success") {
      //       let results = res.data.data.list
      //       if(results){
      //         cb(results);
      //       }
      //     }
      //   })
      //   .catch((err) => {});

      GetQccNew(params).then(res=>{
        if(res.data.msg == "success"){
          let results = res.data?.data?.data?.list || []
          if(results){
            cb && cb(results);
          }
        }
      })

      // collectionCorp({name:queryString}).then(res=>{
      //   if(res.data.msg == "success"){
      //     let results = res.data.data.list
      //     if(results){
      //       cb(results);
      //     }
      //   }
      // })
      
    },
    handleSelect(item) {
      this.temp.taxNo = item.taxNo
      this.temp.districtCode = item.addressCode
      // if(item.qualification == "-"){
      //   this.temp.type = ''
      // }else if (item.qualification.indexOf("一般") != -1){
      //   this.temp.type = '2'
      // }else{
      //   this.temp.type = '1'
      // }
    },
    resetTemp1() {
      this.temp = {
        name: "",
        districtCode: "",
        districtName:'',
        regDate: "",
        taxNo: "",
        type: "",
        accountSystem: "",
        loginMethod: "新版登录",
        personalLoginType: "申报密码登录",
        personalIdno: "",
        personalTaxPwd: "",
        startAccountPeriod:'',
        duePeriod:"",
        password: "",
        xzsf:'',
        idNo: "",
        idType: "居民身份证",
        jbr: '',
        jbrIdno: '',
        zzridno:'',
        zzrmn:'',
        zzrxm:'',
        companyType:'有限责任公司',
        mobileRec:'',
        comSetUpYear:"",
        socialLoginMethod:'账号密码登录',
        socialPassword:'',
        yzmType:1
      };
    },
    handleCreate() {
      this.resetTemp1();
      this.userPass = {
        loginMethod: "",
      }
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
      });
    },
    // 新增确定时
    createData() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          const temp = {
            name: this.temp.name,
            districtCode: this.temp.districtCode,
            regDate: this.temp.regDate,
            taxNo: this.temp.taxNo,
            type: this.temp.type,
            accountSystem: this.temp.accountSystem,
            companyType:this.temp.companyType,
            loginMethod: this.temp.loginMethod,
            personalLoginType: this.temp.personalLoginType,
            personalTaxPwd: this.temp.personalTaxPwd,
            zzrmn: this.temp.zzrmn,
            zzrxm: this.temp.zzrxm,
            zzridno: this.temp.zzridno,
            password: this.temp.password,
            idNo: this.temp.idNo,
            idType: this.temp.idType,
            startAccountPeriod: parseTime(this.temp.startAccountPeriod,"{y}{m}"),
            duePeriod: this.temp.duePeriod,
            jbr: this.temp.jbr,
            jbrIdno: this.temp.jbrIdno,
            xzsf: this.temp.xzsf,
            mobileRec:this.temp.mobileRec,
            personalIdno:this.temp.personalIdno,
            comSetUpYear:this.temp.comSetUpYear,
            socialLoginMethod:this.temp.socialLoginMethod,
            socialPassword:this.temp.socialPassword,
            yzmType: this.temp.yzmType
          };
          saveEaCompany({list:[temp]}).then(res => {
            if(res.data.data.success_total == 0){
              this.$qzfMessage("创建失败",2) 
              this.failList = res.data?.data?.fail_list || []
              this.dialogTableVisible = true
            }else{
              this.dialogFormVisible = false;
              this.$qzfMessage("创建成功") 
              this.$emit('success')
            }
          });
        }
      });
    },
    checkImage(type){
      if(type == 'zzrmn'){
        this.imgSrc = ['https://file.listensoft.net/mnt/v2/operatingSteps/zzrmm.png']
      }else if(type == 'mobile'){
        this.imgSrc = ['https://file.listensoft.net/mnt/v2/operatingSteps/bssgh.png']
      }else if(type == 'gs'){
        if(this.temp.personalLoginType == "实名登录"){
          this.imgSrc = ['https://file.listensoft.net/mnt/v2/operatingSteps/zzrkjdsmlogin.png']
        }else{
          this.imgSrc = ['https://file.listensoft.net/mnt/v2/operatingSteps/zzrkjdsbmmlogin.png']
        }
      }
      this.showImagePreview = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.common_dialog_box .common_title{
  font-size: 14px;
  color: #333;
  font-weight: 600;
  line-height: 36px;
  border-bottom: 1px solid #c2c2c2;
  margin-bottom: 10px;
}
.common_dialog_box .common_title span{
  color: red;
  margin-left: 30px;
  font-weight: normal !important;
}
.common_dialog_box .el-select{
  margin:0 !important;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.help{
  color: #17a2b8;
  font-size: 13px;
  position: absolute;
  right: -44px;
  cursor: pointer;
  i{
   color: #17a2b8;
   font-size: 13px;
   margin-left: 3px;
  }
}
</style>